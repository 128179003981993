module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Onewayecommerce","short_name":"Onewayecommerce","start_url":"/","background_color":"#2E4C1B","theme_color":"#2E4C1B","display":"minimal-ui","icon":"src/images/oneway-logo-img.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4a1db15bcb75e09bc638dcfe023b46f7"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","locale":"en_IE","url":"https://www.onewayecommerce.com/","site_name":"Onewayecommerce"},"twitter":{"handle":"@onewayecommerce","site":"@onewayecommerce","cardType":"summary_large_image"}},
    },{
      plugin: require('../../gatsby-theme-oneway-ui-components/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
